export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/portal/portal/src/pages/index.vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/:404(.*)',
    name: '/[...404]',
    component: () => import('/home/runner/work/portal/portal/src/pages/[...404].vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/aftersale',
    name: '/aftersale',
    component: () => import('/home/runner/work/portal/portal/src/pages/aftersale.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/confirmregistration',
    name: '/confirmregistration',
    component: () => import('/home/runner/work/portal/portal/src/pages/confirmregistration.vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/home/runner/work/portal/portal/src/pages/dashboard.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/installation',
    /* internal name: '/installation' */
    /* no component */
    children: [
      {
        path: 'deliverydetail',
        /* internal name: '/installation/deliverydetail' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/installation/deliverydetail/[id]',
            component: () => import('/home/runner/work/portal/portal/src/pages/installation/deliverydetail/[id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'documents',
        /* internal name: '/installation/documents' */
        /* no component */
        children: [
          {
            path: ':installation_id',
            name: '/installation/documents/[installation_id]',
            component: () => import('/home/runner/work/portal/portal/src/pages/installation/documents/[installation_id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'inspectiondetail',
        /* internal name: '/installation/inspectiondetail' */
        /* no component */
        children: [
          {
            path: ':id()_:source',
            name: '/installation/inspectiondetail/[id]_[source]',
            component: () => import('/home/runner/work/portal/portal/src/pages/installation/inspectiondetail/[id]_[source].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'installationoverview',
        /* internal name: '/installation/installationoverview' */
        /* no component */
        children: [
          {
            path: ':installation_id',
            name: '/installation/installationoverview/[installation_id]',
            component: () => import('/home/runner/work/portal/portal/src/pages/installation/installationoverview/[installation_id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'invoicedetail',
        /* internal name: '/installation/invoicedetail' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/installation/invoicedetail/[id]',
            component: () => import('/home/runner/work/portal/portal/src/pages/installation/invoicedetail/[id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          },
          {
            path: 'list',
            /* internal name: '/installation/invoicedetail/list' */
            /* no component */
            children: [
              {
                path: ':id',
                name: '/installation/invoicedetail/list/[id]',
                component: () => import('/home/runner/work/portal/portal/src/pages/installation/invoicedetail/list/[id].vue'),
                /* no children */
                meta: {
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      },
      {
        path: 'netoperator',
        /* internal name: '/installation/netoperator' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/installation/netoperator/[id]',
            component: () => import('/home/runner/work/portal/portal/src/pages/installation/netoperator/[id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'offerdetail',
        /* internal name: '/installation/offerdetail' */
        /* no component */
        children: [
          {
            path: ':id()_:version',
            name: '/installation/offerdetail/[id]_[version]',
            component: () => import('/home/runner/work/portal/portal/src/pages/installation/offerdetail/[id]_[version].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          },
          {
            path: 'list',
            /* internal name: '/installation/offerdetail/list' */
            /* no component */
            children: [
              {
                path: ':id',
                name: '/installation/offerdetail/list/[id]',
                component: () => import('/home/runner/work/portal/portal/src/pages/installation/offerdetail/list/[id].vue'),
                /* no children */
                meta: {
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      },
      {
        path: 'orderdetail',
        /* internal name: '/installation/orderdetail' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/installation/orderdetail/[id]',
            component: () => import('/home/runner/work/portal/portal/src/pages/installation/orderdetail/[id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'repairs',
        /* internal name: '/installation/repairs' */
        /* no component */
        children: [
          {
            path: ':repairs_id',
            name: '/installation/repairs/[repairs_id]',
            component: () => import('/home/runner/work/portal/portal/src/pages/installation/repairs/[repairs_id].vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          },
          {
            path: 'list',
            /* internal name: '/installation/repairs/list' */
            /* no component */
            children: [
              {
                path: ':installation_id',
                name: '/installation/repairs/list/[installation_id]',
                component: () => import('/home/runner/work/portal/portal/src/pages/installation/repairs/list/[installation_id].vue'),
                /* no children */
                meta: {
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/installations',
    name: '/installations',
    component: () => import('/home/runner/work/portal/portal/src/pages/installations.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/home/runner/work/portal/portal/src/pages/login.vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/monitoring',
    name: '/monitoring',
    component: () => import('/home/runner/work/portal/portal/src/pages/monitoring.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/support',
    name: '/support',
    component: () => import('/home/runner/work/portal/portal/src/pages/support.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/support/:issue_id',
    name: '/support.[issue_id]',
    component: () => import('/home/runner/work/portal/portal/src/pages/support.[issue_id].vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/support/createissue',
    name: '/support.createissue',
    component: () => import('/home/runner/work/portal/portal/src/pages/support.createissue.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  }
]
